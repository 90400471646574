<template>
    <div class="container">
        <div @click="goback" style="font-size: 14px; margin-bottom: 10px; cursor: pointer; width: 50px">
            <i class="el-icon-back"></i>
            返回
        </div>
        <el-form :model="form" :rules="rules" ref="form" style="width: 650px">
            <!-- <el-form-item label="终端编码" label-width="130px">
        <el-select v-model="form.appid">
            <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
      </el-form-item> -->
            <el-form-item label="模板类型" label-width="130px">
                <el-select v-model="form.template_type_id" @change="TempTypeChange">
                    <el-option v-for="item in messTypelist" :key="item.template_type" :value="item.id"
                        :label="item.name">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="emailsendershow" label="邮箱发件人" label-width="130px">
                <el-select v-model="form.emailbox_sender_code" @focus="GetSenderList">
                    <el-option v-for="item in senderList" :key="item.code" :label="item.from_name" :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-else label="推送类型" label-width="130px">
                <el-select v-model="form.only_push">
                    <el-option label="默认(保存用户消息)" :value="0"></el-option>
                    <el-option label="仅推送" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="任务类型" label-width="130px">
                <el-select v-model="form.task_type">
                    <el-option label="即时下发" :value="1"></el-option>
                    <el-option label="延时下发" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="form.task_type == 2" label="任务推送时间" label-width="130px">
                <el-date-picker v-model="date1" :clearable="false" @blur="focusEvent('task_send_time')" type="datetime"
                    placeholder="推送时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="规则类型" label-width="130px">
                <el-tabs type="border-card">
                    <el-tab-pane label="用户基础信息">
                        <!-- user_basis -->
                        <el-form-item label="登陆起始时间" label-width="130px">
                            <el-date-picker v-model="date2" :clearable="false" @blur="focusEvent('login_timestart')"
                                type="datetime" placeholder="登陆起始时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="登陆结束时间" label-width="130px">
                            <el-date-picker v-model="date3" :clearable="false" @blur="focusEvent('login_timeend')"
                                type="datetime" placeholder="登陆结束时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="注册起始时间" label-width="130px">
                            <el-date-picker v-model="date4" :clearable="false" @blur="focusEvent('register_timestart')"
                                type="datetime" placeholder="注册起始时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="注册结束时间" label-width="130px">
                            <el-date-picker v-model="date5" :clearable="false" @blur="focusEvent('register_timeend')"
                                type="datetime" placeholder="注册结束时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="手机类型" label-width="130px">
                            <el-select v-model="form.phone_type" multiple>
                                <el-option label="IOS" value="IOS"></el-option>
                                <el-option label="Android" value="Android"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="版本信息" label-width="130px">
                            <el-select v-model="form.app_version" multiple @focus="GetSoftVersion">
                                <el-option v-for="item in softVersionList" :key="item.version_number"
                                    :label="item.version_number" :value="item.version_number">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="国家" label-width="130px">
                            <el-select v-model="form.country" multiple @focus="GetCountry">
                                <el-option v-for="item in countryList" :key="item.code" :label="item.chinese"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="云存储" label-width="130px">
                            <el-select v-model="form.set_meal">
                                <el-option label="无有效云的用户" value="false">
                                </el-option>
                                <el-option label="有有效云的用户" value="true">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="指定账户">
                        <el-form-item label="账号" label-width="50px">
                            <el-input v-model="form.account" type="textarea" placeholder="可以填写多个账号，逗号隔开"
                                autocomplete="off"></el-input>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="用户和设备的关联信息">
                        <el-form-item label-width="50px" v-for="(item, key) in modelversionList" label="型号版本"
                            :key="key">
                            <el-autocomplete style="width: 300px" @select="GetSolidVersionList(key)"
                                v-model="item.model" :fetch-suggestions="QuerySearchModel" placeholder="产品型号"
                                @change="GetSolidVersionList(key)" />
                            <!-- <el-select v-model="item.model" @change="GetSolidVersionList(key)" placeholder="产品型号">
                <el-option
                  v-for="item in modellist"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>
              </el-select> -->
                            <el-select style="margin-top: 10px" v-model="item.version" multiple placeholder="版本号">
                                <el-option v-for="item in solidVersionlist" :key="item.version_code"
                                    :label="item.version_code" :value="item.version_code">
                                </el-option>
                            </el-select>
                            <el-button style="position: absolute; margin-left: 10px" size="mini" type="warning"
                                @click.prevent="removeLanguage(item)">删除</el-button>
                        </el-form-item>
                        <el-form-item label-width="130px">
                            <el-button type="success" @click="Addmodelversion">新增用户设备关联</el-button>
                        </el-form-item>
                    </el-tab-pane>
                    <!-- <el-tab-pane label="品牌选择">
            <el-form-item label-width="130px">
              <el-select v-model="form.client_brand_code"  @focus="GetBrand">
                <el-option
                  v-for="item in brandlist"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-tab-pane> -->
                </el-tabs>
            </el-form-item>
            <el-form-item label-width="130px">
                <el-button type="info" @click="submitForm('form')">推送</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script type="text/ecmascript-6">
export default {
    name: "",
    props: {},
    setup() {
        return {}
    },
    mixins: [],
    components: {},
    data() {
        return {
            form: {
                message_template_head_code: "",
                appid: "",
                template_type_id: "",
                only_push: 0,
                emailbox_sender_code: "",
                task_type: 1,
                task_send_time: null,
                type: "",
                // user_basis
                login_timestart: "",
                login_timeend: "",
                register_timestart: "",
                register_timeend: "",
                phone_type: "",
                app_version: "",
                country: "",
                // designated_account
                account: "",
                // associated_device
                model: "",
                version: "",
                client_brand_code: [],
                set_meal: ''
            },
            rules: {
                template_type_id: [
                    { required: true, message: "请选择消息模板类型", trigger: "change" }
                ],

            },
            applist: [],
            senderList: [],
            softVersionList: [],
            countryList: [],
            messTypelist: [],
            brandlist: [],
            // messIdType: {},
            // 任务推送时间
            date1: "",
            date2: "",
            date3: "",
            date4: "",
            date5: "",
            modelversionList: [],
            modellist: [],
            solidVersionlist: [],
            typeShow: true,
            emailsendershow: false,
        }
    },
    computed: {},
    watch: {},
    methods: {
        // 型号联想
        QuerySearchModel(queryString, cb) {
            var thinkdata = this.modellist
            var results = queryString
                ? thinkdata.filter(this.createStateFilter(queryString))
                : thinkdata
            cb(results)
        },
        createStateFilter(queryString) {
            return (state) => {
                return state.value.indexOf(queryString) === 0
            }
        },
        TempTypeChange(val) {
            let template_type = ''
            this.messTypelist.forEach(item => {
                if (item.id == val) {
                    template_type = item.template_type
                }
            })
            if (template_type == 1) {
                this.emailsendershow = true
            } else {
                this.emailsendershow = false
            }
        },
        Addmodelversion() {
            this.modelversionList.push({
                model: "",
                version: "",
            })
        },
        removeLanguage(item) {
            var index = this.modelversionList.indexOf(item)
            if (index !== -1) {
                this.modelversionList.splice(index, 1)
            }
        },
        // 获取品牌列表
        GetBrand() {
            this.api.BrandList({
                page: 1,
                limit: 100,
            }).then(res => {
                if (res.data.code == 200) {
                    this.brandlist = res.data.data.data
                    return
                }
                this.$message.error('获取失败' + res.data.msg)
            })
        },
        focusEvent(item) {
            let timeType = ""
            let dateType
            switch (item) {
                case "task_send_time":
                    timeType = "task_send_time"
                    dateType = this.date1
                    break
                case "login_timestart":
                    timeType = "login_timestart"
                    dateType = this.date2
                    break
                case "login_timeend":
                    timeType = "login_timeend"
                    dateType = this.date3
                    break
                case "register_timestart":
                    timeType = "register_timestart"
                    dateType = this.date4
                    break
                case "register_timeend":
                    timeType = "register_timeend"
                    dateType = this.date5
                    break
                default:
                    break
            }
            if (dateType) {
                if (this.form[timeType] == dateType.getTime() / 1000) {
                    return
                }
                this.form[timeType] = dateType.getTime() / 1000
            } else {
                this.form[timeType] = ""
            }
        },
        GetappCode() {
            this.api
                .ClientList({
                    page: 1,
                    limit: 100,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.applist = res.data.data.data

                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        GetMessType() {
            this.api
                .TemplateTypeList({
                    page: 1,
                    limit: 100,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.messTypelist = res.data.data.data
                        let defaultIndex = ''
                        this.messTypelist.forEach((item, index) => {
                            if (item.template_type == 1) {
                                item.name = "邮件"
                            } else if (item.template_type == 2) {
                                defaultIndex = index
                                item.name = "文本消息"
                            } else {
                                item.name = "图文"
                            }
                        })
                        this.form.template_type_id = this.messTypelist[defaultIndex].id
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        GetSenderList() {
            this.api
                .SenderList({
                    page: 1,
                    limit: 100,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.senderList = res.data.data.data
                        this.GetSoftVersion()
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        GetSoftVersion() {
            this.api
                .SoftList({
                    page: 1,
                    limit: 100,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.softVersionList = res.data.data.data
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        GetCountry() {
            this.api
                .CountryList({
                    page: 1,
                    limit: 100,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.countryList = res.data.data.data
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        // GetModelList() {
        //   this.api
        //     .ProductModelList({
        //       page: 1,
        //       limit: 100,
        //     })
        //     .then((res) => {
        //       if (res.data.code == 200) {
        //         res.data.data.data.forEach((item) => {
        //           this.modellist.push({
        //             value: item.code,
        //             code: item.code,
        //             name: item.name,
        //             model: item.model,
        //           });
        //         });
        //         // this.modellist = res.data.data.data
        //         return;
        //       }
        //       this.$message.error("获取失败" + res.data.msg);
        //     });
        // },
        GetSolidVersionList(key) {
            this.modelversionList[key].version = []
            this.api
                .FirmwareList({
                    page: 1,
                    limit: 100,
                    product_model_code: this.modelversionList[key].model,
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.solidVersionlist = res.data.data.data
                        return
                    }
                    this.$message.error("获取失败" + res.data.msg)
                })
        },
        submitForm(formName) {
            let params = {
                message_template_head_code: this.form.message_template_head_code,
                // appid: this.form.appid,
                template_type_id: +this.form.template_type_id,
                only_push: this.form.only_push,
                emailbox_sender_code: this.form.emailbox_sender_code,
                task_type: this.form.task_type,
                task_send_time: this.form.task_send_time,
                type: this.form.type,
            }
            let tempaccount = this.form.account.split(",")
            let associatedDeviceRule = []
            for (var key in this.modelversionList) {
                associatedDeviceRule.push({
                    model: this.modelversionList[key].model,
                    version: this.modelversionList[key].version,
                })
            }

            params.user_rules = [
                {
                    type: "user_basis",
                    rule: {
                        login_time: {
                            start: this.form.login_timestart ? this.form.login_timestart : 0,
                            end: this.form.login_timeend ? this.form.login_timeend : 0,
                        },
                        register_time: {
                            start: this.form.register_timestart
                                ? this.form.register_timestart
                                : 0,
                            end: this.form.register_timeend ? this.form.register_timeend : 0,
                        },
                        phone_type: this.form.phone_type,
                        app_version: this.form.app_version,
                        country: this.form.country,
                    },
                },
                {
                    type: "designated_account",
                    rule: {
                        account: tempaccount,
                    },
                },
                {
                    type: "associated_device",
                    rule: associatedDeviceRule,
                },
                {
                    type: "set_meal",
                    rule: this.form.set_meal,
                },
                // {
                //   type: "client_brand_code",
                //   rule: this.form.client_brand_code+'',
                // },
            ]
            params.user_rules = JSON.stringify(params.user_rules)
            this.api.TemplateTaskCreate(params).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success("推送成功")
                    return
                }
                this.$message.error("推送失败" + res.data.msg)
            })
        },
        goback() {
            this.$router.push("/message/templatemessage")
        },
    },
    filters: {},
    mounted() {
        this.form.message_template_head_code = this.$route.params.code
    },
    created() {
        this.GetMessType()
    },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.el-form-item .el-form-item {
  margin-bottom: 0;
  margin-top: 10px;
}

.el-form-item .el-form-item .el-select {
  width: 300px;
}
</style>
